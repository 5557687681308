@media screen and (min-width: 320px) and (max-width: 768px) {
.fund-body .card .account-item.active{ 
        border: 1px solid rgb(255, 209, 4);
        
}
.fund-body .card .account-item{
        border: solid 1px #444;
        margin: 5px;
        width: 30%;
        
}
.fund-nav .nav-item.active{
    animation: slideInFromLeft 0.3s ease-out forwards;
        padding: 5px 5px !important;
        border-radius: 5px;
        border: 2px solid #1f684e;
        scale: 1.28;
}


.fund-body.deposit.open {
    display: block;
    animation: slideInFromLeft 0.3s ease-out forwards;
    
}

.fund-body.deposit{
    display: none;
}
.fund-body.withdraw{
    display: none;
    animation: slideInFromRight 0.3s ease-out forwards;
}
.fund-body.withdraw.open{
    display: block;
    
}
.card .account-item{
    text-align: center;
    padding: 5px;
}
.fund-body .item-icon{
    width: 30px;
    height: 30px;
}
.fund-body .card p{
    padding: 0;
}
.fund-body .card-body{
    flex-wrap: wrap;
    justify-content: start;
    
}
.item-icon.bank{
    width: 70px;
}
body, html {
    margin: 0;
    padding: 0;
    height: 100%;
  }
.funds {
    height: 100%; /* Ensure parent takes full height */
    overflow: auto; /* Make sure parent doesn't scroll */
    display: block !important;
  }
  
  .fund-body {
    flex: 1 1 auto; /* Take up remaining space */
    overflow-y: auto; /* Allow vertical scrolling */
    padding: 10px;
    margin-bottom: 30px;
    
  }
  .amount-selected{
    padding: 5px;
    margin-top: 10px;
    border-bottom: 1px solid #444;
    display: flex;
    align-items: center;
  }
  .btn{
    width: 100%;
    background: #1f684e;
    color: #fff;
    height: 45px;
  }
  .bottom-nav{
    display: block !important;
}
.mobile-nav{
    display: flex !important;
}
}
.funds{
    display: none;
}
.bottom-nav{
    display: none;
}
.mobile-nav{
    display: none;
}

@keyframes slideInFromLeft {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
@keyframes slideInFromRight {
    0% {
        opacity: 0;
        transform: translateX(100%);
        
    }
    100% {
        opacity: 1;
        transform: translateX(0);
        
    }
}