@media screen and (min-width: 320px) and (max-width: 768px) {
.top-nav{
    display: none;
}
.header-menu{
    display: none;
}
.header-logo img{
    width: 100px;
}
.algin{
    margin: 10;
    padding: 0;
}
.game-lunch{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
}
.header-nav{
    height: 50px;
    line-height: 40px;
    padding: 0 20px;
    background: #000;
    position: fixed;
    z-index: 1;
    width: 100%;
}

.spon-icon_u {
    width: 25px;
}
.nav-menu{
    display: none;
}
.mobile-nav{
    display: flex;
    overflow: scroll;
    
}
.mobile-nav li{
    float: left;
    margin: 10px;
    color: #fff;
}
.mobile-nav li a{
    color: #fff;
}
.mobile-nav ul{
    display: flex;
    overflow-x: scroll;
}
body{
    background: #000;
}
.bottom-nav{
    height: 52px;
    background: #000;
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
    
}
.bottom-nav a {
    color: #fff;
    font-size: 11px;
}
.bottom-nav ul{
    display: flex;
    justify-content:space-around;
    align-items: center;
    height: 52px;
    margin: 0;
    padding: 0;
}
.funds{
    position: fixed;
    width: 100%;
    height: 100vh;
    background: #131212;
    z-index: 10;
}
.fund-action-bar{
    display: flex;
    justify-content: space-between;
    color: #fff;
    padding: 20px;
    align-items: center;
}
.fund-header{
    background: #14805e;
    
}
.fund-nav ul{
 display: flex;
 justify-content: space-around;
 padding: 0;
 margin: 0;
}
.fund-nav li{
    background: #1f684e;
    padding: 3px 5px ;
    width: 50%;
    color: #fff;

}
.open{
    display: block;
}
.close{
    display: none;
}
.main-balance-right p{
    font-size: 12px;
}
.main-balance-right span{
    font-size: 12px;
}
.record-nav{
    padding: 1px;
}
.nav-item.active{
    background: #3bbe90;
}
.fund-body{
    overflow: scroll;
    background: #333;
    width: 100%;
    height: 100%;
    color: #fff;
    text-align: start;
    padding: 10px;
}
.payment-m-list{
    border: #f8b705 2px solid;
    padding: 10px 0px;
    margin: 5px;
    text-align: center;
}
.payment-m-list img{
    width: 100%;
}
.deposit-amount-item{
    border: solid 1px gray;
    padding: 10px 0;
    text-align: center;
}
.mobile-d-none{
    display: none;
}
.main-slider{
   padding-top: 10px;
}
.max-width{
    position: relative;
    top: 50px;
}
.tap-bar {
    width: 100%;
    display: none !important;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    
}

.tap-bar .content {
    padding: 20px;
    box-sizing: border-box;
}

.tap-bar.open {
    display: block !important;
}


.mobile-not-login{
    display: flex;
    align-items: center;
    width: 100%;
}
.mobile-not-login .nav-item{
    width: 100%;
}
.mobile-not-login .btn-ui{
    padding: 10px 10px;
    width: 100%;
}
.mobile-not-login .bottom-nav-item{
    background: #fff;
    padding: 10px;
}
.tap-bar .close svg{
    color: #fff;
}
.tap-bar .nav-menu
    {
        display: flex !important;
        width: 70%;
        background:  #222;
        height: 100%;
        padding-top: 10px;
        
    }
.tap-bar .nav-menu .navmenu{
    flex-direction: column;
    justify-content: normal;
    height: 100vh;
    overflow-y: auto;
    
}


.tap-bar .nav-menu .navmenu .nav-item{
    display: flex !important;
    align-items: center;
    padding: 10px 0;
}
.tap-bar .nav-menu .navmenu .nav-link img{
    width: 35px;

}
.display-none{
    display: inline !important;
}
.tap-bar .nav-menu .navmenu .nav-item{
    border-bottom: 1px solid #444;
}
.slick-dots{
    position: relative;
    bottom: 0;
}
/* Main slider container */
.main-slider {
    
    width: 100%;
  }

  /* Slick slider dot styles */
  .slick-dots {
    display: flex !important;
    justify-content: center;
    
    padding: 0;
    list-style: none;
  }
  .slick-slide img{
    height: 150px;
    border-radius: 10px;
  }
  
  .slick-dots li {
    margin: 0 20px;
  }
  
  .slick-dots .custom-dot {
    width: 40px; /* Adjust width to match the design */
    height: 5px; /* Adjust height to match the design */
    background: #ddd; /* Inactive state color */
    display: block;
    cursor: pointer;
    transition: background 0.3s, transform 0.3s;
  }
  
  .slick-dots .custom-dot:hover,
  .slick-dots .custom-dot:focus {
    background: #aaa; /* Hover state color */
  }
  
  .slick-dots .slick-active .custom-dot {
    background: #333; /* Active state color */
    transform: scale(1.2);
  }
  .main-slider .slick-slide {
    opacity: 0.5;
    
    transition: transform 0.3s ease, opacity 0.3s ease;
  }
  
  .main-slider .slick-center {
    opacity: 1;
    padding: 0px 10px;
    
  }

  /* mobile menu */

  .mobile-nav .nav-item.active{
    background: #333;
  }
  .mobile-nav .nav-item{
    padding: 10px;
    margin: 10px 0px !important;
    
  }
  .mobile-nav .nav-item.active::after {
    content: "";
    display: flex; /* Initially hidden */
    position: absolute;
    transform: translateX(25px) !important;
    z-index: 2;
    justify-content: center;
    border-width: 5px; /* Border width based on viewport width */
    border-style: solid;
    border-color: transparent transparent #111111; /* Transparent top borders, solid black bottom border */
}
.menu-overview-list.active{
    display: grid !important;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: .5333333333vw;
    width: 100%;
    height: auto;
    margin: 5px;
    transition: transform 0.3s ease; /* Animation for sliding */

}
.menu-overview{
    display: block !important;
}
.menu-overview-list-item{
    width: 100%;
    background: #333;
    padding: 11px;
    position: relative;
   
    
}
.menu-overview-list-item p{
    color: #fff;
    font-size: 3.4666666667vw;
    margin-top: 1.0666666667vw;
    text-align: center;
    line-height: 14px;
}
/* Favourties Games */
.favourite-games {
    display: block !important;
    background: #333;
    padding: 10px;
    width: 100%;
    height: auto;
    overflow: hidden; /* Changed to hidden */
}

.favourite-games-items ul {
    display: flex; /* Use flexbox for horizontal layout */
    overflow-x: scroll; /* Enable horizontal scrolling */
    padding: 0;
    margin: 0;
    list-style: none; /* Remove default list styling */
}

.favourite-games-items ul li {
    margin-right: 10px; /* Space between images */
}

.favourite-games-items ul li img {
    width: 300px;
    border: #14805e 2px solid;
}
.referral-content{
    background: #111;
}
.ref-content-header {
    background: url(../img/referral-program-banner.png);
    background-size: cover;
    height: 400px;
    background-repeat: no-repeat;
    display: flex;
    padding-top: 50px;
    flex-direction: column;
    align-items: center;
    position: relative; /* Ensure that ::after is positioned relative to this element */
    overflow: hidden;   /* If there's a need to hide overflow */
    
}

.ref-content-header::before {
    content: ''; /* Ensure the pseudo-element is displayed */
    background: url(../img/referral-program-bg.png);
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; /* Ensure it's behind the content */
}

/* featured-game */
.featured-game ul li img {
    width: 200px !important;
}

/* tap-bar-section */
.tap-bar .mobile-nav .nav-item.home-nav{
    display: block;
}
.tap-bar .mobile-nav .nav-item {
    float: none;
    border-bottom: 1px solid #777;
   
}
/* refprogram */
.ref-title{
    display: block;
    white-space: nowrap;
    text-align: center;
    text-shadow: 0vw .5333333333vw 1.0666666667vw #000;
    font-size: 7.7333333333vw;
    line-height: 1;
    font-weight: 900;
    z-index: 2;
}
.ref-logo{
    width: 100px !important;
    z-index: 2;
}
.tap-bar .mobile-nav .nav-item div{
    float: none;
    display: flex;
}
.tap-bar .mobile-nav ul li img{
    width: 25px;
}
.tap-bar .mobile-nav ul{
    display: block;
    width: 100%;
}
.tap-bar .content-title h2{
 display: none;
}
.mobile-nav .nav-item.home-nav{
    display: none;
}
.tap-bar .menu-overview-list-item{
    border-bottom: 1px solid #444;
    
}

.tap-bar .mobile-nav .nav-item{
    background: #222;
}
.tap-bar .mobile-nav {
    background: #111;
    height: 100vh;
    width: 200px;
    overflow: auto;
}

.tap-bar .mobile-nav .nav-item p{
    padding-left: 10px;
}
.tap-bar .mobile-nav .nav-item{
    padding: 20px 10px;
    margin: 0 !important;
}


.tap-bar .menu-overview {
    width: 30%;
    background: #333;
    height: 100vh;
    overflow: auto;
    
}


.tap-bar .menu-overview-list.active{
    display: flex !important;
    flex-direction: column;
    animation: slideInFromLeft 0.5s ease-out; 
    
}



.announcement-icon img{
    
   
    filter: invert(42%) sepia(99%) saturate(431%) hue-rotate(123deg) brightness(93%) contrast(85%);
    
    

    
}
.menu-overview-list{
    display: none;
}
.sports-icon-menu{
    
    filter: invert(84%) sepia(89%) saturate(637%) hue-rotate(1deg) brightness(106%) contrast(101%);

}
.content-title h2:before {
    content: "";
    display: inline-block;
    width: 1.0666666667vw;
    height: 4vw;
    margin-right: 1.3333333333vw;
    background: #14805e;
    border-radius: .5333333333vw;
    vertical-align: middle;
}
.content-title h2 {
    color: #fff;
    font-size: 4vw;
    font-weight: 700;
    text-align: start;
    padding-left: 5px;
}
.mobile-nav ul{
    padding: 0;
    margin: 0;
}
.menu-overview-list-item img{
    width: 50%;
}
/* Bottom Nav not login */
.register{
    display: block !important;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 10000;
    overflow: scroll;
}
  .not-login-bottom-nav .lang-bottom-menu{
    display: flex;
    flex: 2;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 1.3333333333vw;
    background: #d7e3f0;
    text-align: center;
    height: 52px;
    width: 30%;
    font-weight: bold;
  }
  .not-login-bottom-nav{
    justify-content: center;
    align-items: center ;
  }
  .lang-bottom-menu img{
    width: 6.6666666667vw;
    height: auto;
    margin-right: 1.3333333333vw;
    border-radius: 100%;
  }
  .login-bottom-menu {
    width: 50%;
    height: 52px;
    background: #14805e;
    font-size: 4vw !important;
    font-weight: 700;
    line-height: 13.3333333333vw;
    text-align: center;
    color: #fff;
}
.signup-bottom-menu {
    width: 50%;
    height: 52px;
    background: #ffdf1a;
    font-size: 4vw !important;
    font-weight: 700;
    line-height: 13.3333333333vw;
    text-align: center;
    color: #000 !important;
}
.bottom-action{
    display: flex;
    width: 70%;
}
}
.tap-bar{
    display: none;
}
.display-none{
    display: none;
}

.announcement {
    justify-content: center;
    overflow: hidden; /* Hide the overflow text */
    white-space: nowrap; /* Ensure text is in one line */
    box-shadow: 0 4px 8px rgba(133, 101, 101, 0.1); /* Example shadow: 0px offset, 4px blur, 8px spread */
}


  
  .announcement-text-wrapper {
    
    overflow: hidden;
    position: relative;
    
    
  }
  
  .announcement-text {
    display: inline-block;
    padding-left: 100%;
    animation: scroll-left 21s linear infinite;
    color: #fff;
  }
  
  .announcement-text {
    display: inline-block;
    padding-left: 100%;
    animation: scroll-left 21s linear infinite;
    color: #fff;
  }
  
  @keyframes scroll-left {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  .menu-overview{
    display: none;
  }

  .favourite-games{
    display: none;
  }
  @keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}
.register{
    display: none;
}
