.header-nav{
    height: 4.375rem;
    background: linear-gradient(to bottom, #1bac7e 0, #14805e 100%);
    align-items: center;
    display: flex;
    justify-content: space-between;
}
.header-logo img{
    width: 150px;
}
.spon-icon_u{
    width: 30px;
    margin: 3px;
}
.header-menu li{
    float: left;
    margin: 0 5px;
    color: #fff;
    padding: 0 15px;
}
.btn-ui{
    background: linear-gradient(to right, #fbc103 0, #f79604 15%, #f79604 30%, #fbc103 55%, #fbc103 100%);
    padding: 5px 30px;
    color: #fff;
    text-shadow: 0 1px 3px #967302;
    border-radius: 5px;
    border: none;
    
}
/* .h-m-hover:hover{
    border: 1px #fbc103 solid;
    border-radius: 10px;
    
} */

/* custom.css */
/* custom.css */
.custom-close .btn-close {
    padding: 10px;
    --bs-btn-close-color: #fff; /* This ensures the close button background color is white */
    --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  }
  

  
