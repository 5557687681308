@media screen and (min-width: 320px) and (max-width: 768px) {
.account-header{
    background: url('../img/member-header-bg.png');
    position: relative;
    width: 100%;
    height: 45.3333333333vw;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: 100%;
}
.close{
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    width: 13.3333333333vw;
    height: 13.3333333333vw;
    border-radius: 0 0 0 100%;
    background: #111;
    display: block;
}
.icon-close{
     color: #fff;
     width: 25px;
     height: 25px;
     margin: 8px;
    
}
p{
    margin: 0;
}
.account {
    z-index: 1000; /* Ensures it's above other elements */
    position: fixed; /* Fixed position relative to the viewport */
    width: 100%; /* Full width of the viewport */
    height: 100%; /* Full height of the viewport */
    background: #000; /* Black background */
    overflow-y: auto; /* Enable vertical scrolling */
    
    color: #fff; /* Optional: add text color */
  }
  
.card{
    width: 100%;
    height: auto;
    margin: 10px 0;
    border-radius: .8vw;
    background: #333;
    padding: 7px;
}
 .card .card-body{
    display: flex;
    justify-content: space-around;
    padding: 0;
}
.account-vip{
    background: #222;
    text-align: start;
    display: flex;
    border-radius: 30px;
}
.account-info{
    display: flex;
    padding-top: 40px;
    
}
.grading-icon img{
    width: 70px;
}
.card p{
    color: #57a879;
    margin: 0;
    padding: 10px 0;
}
.account-body{
    margin: 10px;
    position: relative;
    top: -50px;
}
.card-title-l-line{
    width: 5px;
    height: 22px;
    background: #57a879;
    margin-right: 10px;
}
.card .card-title{
    display: flex;
    align-items: center;
    border-bottom: 1px solid gray;
    padding: 5px 0;
}
.card-title h4{
    color:  #fff;
    font-size: 18px;
    line-height: 18px;
}

.item-icon{
    
    width: 45px;
    height: 45px;
}

.record-nav ul{
    display: flex;
    justify-content: space-around;
}
.record-nav .nav-item .nav-link{
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    
}
.record-nav .nav-item.active .nav-link{
    color: yellow;
    background: transparent;
}

.fund-body table {
    width: 100%; /* Ensures the table takes up full width of its container */
    border-collapse: collapse; /* Removes the space between table cells */
    border: 1px solid #ddd; /* Optional: adds a border to the table */
  }
  
  .fund-body table thead {
    background: #666; /* Sets background color for the header */
    color: #fff; /* Sets text color to white for contrast */
  }
  
  .fund-body table thead tr {
    text-align: center; /* Centers text in the header row */
    height: 30px; /* Sets the height of the header row */
    line-height: 30px; /* Vertically centers text in the header row */
  }
  
  .fund-body table th,
  .fund-body table td {
    padding: 5px; /* Adds padding inside header and data cells */
    border: 1px dashed #ddd; /* Adds border to each cell */
    text-align: center; /* Centers text in each cell */
  }
  
  .fund-body table tbody tr:nth-child(odd) {
    background: #f2f2f2; /* Adds a background color for odd rows */
  }
  
  .fund-body table tbody tr:nth-child(even) {
    background: #fff; /* Ensures even rows have a white background */
  }


  /* profile */

  .profile .profile-header .profile-actio-bar{
        display: flex;
        height: 100px;

  }
  .profile {
    position: absolute;
    width: 100%;
    background: #000;
  }
  .funds.profile .fund-header{
    height: 80px;
  }
  .profile-bg{
    width: 100%;
    padding: 10px;
    border-radius: 20px;
  }
  .profile .fund-body{
    background: #111;
  }
  .profile .badge{
    background: #555;
    padding: 5px 10px;
    margin: 10px;
    color: #fff;
  }
  .profile .fund-body p{
    font-size: 18px;
  }
  .profile-info{
    padding: 10px;
    width: 100%;
    background: #444;
    border-radius: 8px;
  }
  .input-from{
    display: block;
  }
  .input-groups{
    border-bottom: 1px solid #555;
    margin: 5px;
    
  }
  .input-group input{
    background: #333;
    border: none;
    width: 200px;
    padding-left: 30px;
    color: #fff;
    height: 45px;
  }
  .input-group{
    align-items: center;
    margin: 10px 0px;
  }
  .input-group label{
    width: 70px;
  }
  .modal-header{
    background: #333 !important;
    border-bottom: 0;
  }
  .modal-content{
    height: 100vh;
    background: #111;
    
  }
  .modal-body form{
    background: #333;
    padding: 10px;
    border-radius: 8px;
  }
  .form-control{
    background: #333 ;
  }
  .form-check-label{
    color: #fff;
  }
  .form-label{
    color: #fff;
  }
  .input-group-text svg{
    color: #555  !important;
  }
  .input-group-text{
    background: #333;
    border: none;
  }
  .modal-content  .input-group input:focus{
    background: #333;
    outline: none;
  }
  .modal-content  .input-group input::placeholder{
    color: #555;
  }
  .modal-content  .input-group input{
    border-bottom: solid 1px #555;
    color: #fff;
  }
  .modal{
    --bs-modal-margin: 0 !important;
    -bs-modal-border-color:0 !important;
    --bs-modal-border-width: 0 !important;
    --bs-modal-border-radius: 0 !important;
    --bs-modal-box-shadow: 0 !important;
    --bs-modal-inner-border-radius: 0 !important;
  }
  .modal-content .form-control {
    margin-bottom: 0 !important;
}
    /* Register Page */
    .register-header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: fixed;
      top: 0;
      width: 100%;
      height: 13.3333333333vw;
      border-bottom: .2666666667vw solid rgba(153, 153, 153, .3);
      background: #333;
      z-index: 10;
    }
    .validation-error{
      width: 100%;
      display: block;
      text-align: start;
      opacity: 0.8;
    }
    .register-body {
      background: #222;
      position: relative;
      top: 52px;
      overflow-y: scroll;
      height: 100vh;
      
    }
    .register-body .btn{
      background: #14805e !important;
      color: #fff;
    }
    .bottom-input-group{
      display: flex;
      align-items: center;
      width: 100%;
      flex-direction: row;
    }
    .bottom-input-group label{
      width: 30%;
      color: #fff;
      text-align: start;
    }
    .bottom-input-group .input-field-group{
      width: 70%;
    }
    .bottom-input-group .input-field-group input,select{
      background: #333;
      border: none;
      height: 13.3333333333vw;
      color: #14805e ;
      width: 80%;
    }
    .bottom-input-group .input-field-group input,select:focus{
      outline: none;
    }
    .bottom-input-group{
      border-bottom: 1px solid #777;
    }
    .bottom-input-group:last-child{
      border: none;
    }
}

/* my Account */

.my-account-nav{
    background: #333;
    padding: 20px 10px;
    border-radius: 7px;
}
.my-account-nav-header img{
    width: 50px;
}

.my-account-nav hr{
    color: #fff;
}
.my-account-nav ul{
    margin: 0;
    padding: 0;
    
}
.account-main-nav .nav-item .nav-link{ 
    color: #fff;
    padding: 5px 10spx;
    margin: 7px 0;
    display: block;
}
.account-main-nav .nav-item .nav-link.active img{
    background: #111;
    border-radius: 100%;
    
    color: #000;
}
.account-main-nav .nav-item .nav-link.active{
    background: #222;
    border-radius: 0;
    
    
}
.account-main-nav .nav-item .nav-link img{
    background: #444;
    border-radius: 100%;
    width: 25px;
    
}
.account-main-nav .nav-item{
    width: 100%;
    text-align: start;
    
}
.main-nav-ac img{
    width: 35px;
}
.account-content{
    
    margin: 0  10px;
    border-radius: 7px;
}

.content-title hr{
    color: #57a879;
    border: dashed 1px;
}
.content-table {
    width: 100%;
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 14px;
    text-align: left;
  }
  
  .content-table thead tr {
    background-color: #009879;
    color: #ffffff;
    text-align: left;
    font-weight: bold;
  }
  
  .content-table th,
  .content-table td {
    padding: 12px 15px;
    border: 1px dashed #dddddd;
    text-align: center;
  }
  
  .content-table tbody tr {
    border-bottom: 1px solid #dddddd;
    background: #999;
  }
  
  .content-table tbody tr:nth-of-type(even) {
    background-color: #777;
  }
  
  .content-table tbody tr:last-of-type {
    border-bottom: 2px solid #009879;
  }

  /* Login */

 .modal-header{
  background: linear-gradient(to bottom, #1bac7e 0, #14805e 100%);
  color: #fff;
  }
  .form-control {
    margin-bottom: 15px;
  }
  
  .input-group-text {
    cursor: pointer;
  }
.input-group-text svg{
    color: #fcac00;
  }

  .custom-input-group .input-group-text,
.custom-input-group .form-control {
  height: 38px;
  padding: 6px 12px;
  line-height: 1.5;
  border-radius: 4px;
}
.btn .btn-primary{
  width: 100% !important;
}
.gradient-button {
  background: linear-gradient(to right, #fbc103 0, #f79604 15%, #f79604 30%, #fbc103 55%, #fbc103 100%);
  height: 45px;
  margin-top: 10px;
  border: none; /* To remove the default border */
}



.referral-program {
  background-color: #14805e;
  color: white;
  width: 100%;
  position: relative;
  z-index: 10;
}

.header {
 
  align-items: center;
  
  
}
.info-content{
  background: url(../img/referral-info-bg.png);
  background-size: cover;
  height: 2400px;
  width: 100%;
}

.back-button {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  
}
.tabs {
  display: flex;
  justify-content: space-around;
  
}
.tab {
  background: none;
  border: none;
  color: white;
  width: 50%;
  cursor: pointer;
  margin-top: 25px;
}

.tab.active {
  border-bottom: 2px solid yellow;
  color: yellow;
  font-weight: bold;
}

.tab-content {
  height: 100%;
  overflow: hidden;
}


/* ref content */
.ref-content-header img{
 width: 100%;
}
.ref-content-qr{
  background: url(../img/referral-panel-bg-1.png);
  border: 1px solid var(--referral-program-panel-border, #666);
    border-radius: 1.3333333333vw;
  padding: 10px;
  background-size: cover;
  transform: translateY(-110px);
  border-radius: 10px;
  z-index: 2;
  position: relative;

}
.ref-content-qr p{
  color: #999;
  margin-bottom: 10px;
}
.qr-action button{
  width: 45%;
  height: 50px;
  border: none;
  margin: 0 5px;
  background: #ffde1a;
  border-radius: 5px;
}

/* my Account */

/* Profile */
.main-balance{
  padding: 10px;
  border-radius: 7px;
  overflow: hidden;
  color: #999;
  background: #333;
}

.main-balance p{
  margin: 0;
  padding: 0;
}
.main-balance-left p{
  text-align: start;
}
.main-balance-left span{
  color: #76bd6a;
  font-weight: bold;
  font-size: 24px;
}
.main-balance-left{
  text-align: justify;
  padding-left: 20px;
  border-right: 1px solid #222;
}
.unverified-icons{
  
  padding: 8px;
  margin: 2px;
  border-radius: 18px;
  color: #fff;
  animation: verifyState .3s ease-in-out alternate-reverse infinite;
    background: #f35e5e;
    display: inline-block;
    transition: .3;
}
/* Recent Activity */
.recent-activity{
  padding: 10px 20px;
  margin: 10px 0;
  border-radius: 7px;
  overflow: hidden;
  background: #333;
}
.recent-activity p{
  color: #999;
}
.recent-activity{
  color: #fff;
  text-align: start;
}
/* Users Informations */

.user-information{
  overflow: hidden;
  
}
.user-info-lists{
  background: #414141;
  padding: 15px 10px;
  border-radius: 3px;
  
}
.users-info-icons{
 color: #999;
 font-size: 20px;
 
}
.birth-edit:hover{
  color: #009879 !important;
}
.user-info-lists span{
  font-size: 15px;
}
.card-content{
  background: #333;
  overflow: hidden !important;
  padding: 10px;
  border-radius: 7px;
}

/* Transaction */

.content-title h4{
  color: #009879 !important;
  font-size: 18px;
}
.content-title h4::before {
  content: "";
  display: inline-block;
  width: .3125rem;
  height: 1.5625rem;
  margin-right: .625rem;
  border-radius: .3125rem;
  background: linear-gradient(to bottom, #26ffbc 0, #00f2a7 100%);
  vertical-align: bottom;
}
.content-table  tr{
  border: #009879 solid 1px;
}
/* Rest Password */
.password-notice{
  padding: 15px;
  border-radius: 7px;
  border: 1px solid #7293e1;
    background-color: rgba(114, 147, 225, .4);
}
.password-notice svg{
  background: #00f2a7;
  padding: 5px 10px;
  font-size: 14px;
  color: #333;
  border-radius: 20px;
}

.password-notice span{
  color: #00f2a7;
  
}
.password-reset-lists li{
  list-style: inside decimal;
  color: #fff;
  margin-left: 10px;
}
.input-groups{
  text-align: start;
}
.input-groups label{
  display: block;
  color: #fff;
  margin: 5px 0;
}
.input-groups input{
  background: #333;
  border: 1px solid #999;
  width: 100%;
  border-radius: 3px;
  height: 42px;
  color: #fff;
}
.reset-password-btn.btn-ui{
  width: 120px;
  height: 42px;
  display: block;
}
.input-groups input:focus{
  border: solid 1px rgb(255, 196, 0);
}
.payment-method-item{
  width: 19%;
  border: solid 1px #777;
  border-radius: 3px;
  text-align: center;
  padding: 7px 0;
  margin: 3px;
  
  
}
.payment-method-item.active{
  border: solid 1px rgb(255, 196, 0);
  color: rgb(255, 196, 0);
}
.payment-method-item p{
  margin: 0;
  padding: 0;
  color: #777;
}
.payment-method-item img{
  width: auto;
  height: 1.5625rem;
}
.payment-methods{
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
}
.wallets{
  overflow: hidden;
  width: 100%;
}
.deposit-record{
  padding: 10px;
  background: #333;
  border-radius: 7px;
  text-align: center;
  color: #7293e1;
  font-size: .8125rem;
  font-weight: 500;
  height: 250px;
}
.payment-system{
  text-align: start;
  color: #fff;
  background: #009879;
  border-radius: 3px 3px 0px 0px;
  padding: 8px 10px;
  width: 150px;
  margin: 0;
}
.payment-card{
  border-top: solid 1px #009879;
  background: #222;
  height: auto;

}
.payment-card p{
  color: #7293e1;
  font-size: .8125rem;
  font-weight: 500;
  text-align: start;
  padding: 10px 0;
}
.channel{
  border: 1px solid #777;
  padding: 10px 15px;
  margin: 0px 5px;
  border-radius: 3px;
  color: #777;
}
.channel.active{
  border: solid 1px rgb(255, 196, 0);
  color: rgb(255, 196, 0);
}
.selecetd-amount{
  color: #fff;
  width: 19%;
  border: solid 1px #777;
  border-radius: 3px;
  text-align: center;
  padding: 7px 0;
  margin: 3px;
}
.selecetd-amount.active{
  border: solid 1px rgb(255, 196, 0);
  color: rgb(255, 196, 0);
}
.amount-input{
  background: #222;
  border: 1px solid #777;
  color: #fff;
  height: 34px;

}

/* vip club */
.vip-club{
  background: #333;
  padding: 10px;
  border-radius: 7px;
  width: 100%;
  
}
.vip-card img{
  width: 48px;
  height: 48px;
  
}
.vip-card {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  color: #777;
  padding: 20px 10px;
  align-items: center;
  position: relative; /* Needed for the pseudo-element */
  overflow: hidden; /* Ensures the rotated image doesn't overflow the container */
  width: 65%;
  margin-right: 11px;
}

.vip-card::before {
  content: '';
  position: absolute;
  opacity: 0.5;
  left: 50%;
  width: 100%; 
  height: 100%; 
  background-image: url(../img/bg-vip.svg);
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain; 
  transform: translate(-50%, -50%) rotate(15deg); 
  z-index: 1; 
  mix-blend-mode: overlay;
}

.vip-users{
  text-align: start;
}
.vip-users h4{
 color: #fff;
 font-size: 18px;
}
.vip-info{
  background: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  color: #00f2a7;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 33%;
  
}
.vip-star{
  font-size: 21px;
  margin-bottom: 3px;
  
}
.vip-info p{
  background: red;
  color: #ffff;
  padding: 5px 8px;
  border-radius: 3px;
  font-size: 14px;
  margin: 0;
}
.vip-info p svg{
  background: #fff;
  border-radius: 25px;
  padding: 3px 6px;
  margin-right: 3px;
  font-size: 9px;
  color: red;
}
.max-width{
  overflow: hidden;
}

.live-chat{
  position: fixed;
  z-index: 10000;
  width: 100px;
  height: 100px;
  bottom: 30px;
  right: 0;
}