.top-nav{
    display: flex;
    background: #2d2d2d;
    color: #fff !important;
    height: 45px;
    line-height: 45px;
    font-size: 15px;
    justify-content: space-between;
}
.top-nav-l{
    display: flex;
    
}
.currency-lan img{
    width: 30px;
    height: 30px;
    border-radius: 100%;
    margin-right: 5px;
}
.currency-lan{
    background: #555;
    padding: 0 10px;
}
.top-nav-r li{
    float: left;
    margin: 0 10px;
    
}
.top-nav-r{
    
    text-align: end;
   
}

.algin{
    padding: 0 70px;
}