.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
li{
  list-style: none;
}
a{
  text-decoration: none;
}
body{
  background-color: #4e4e4e;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* sidler */

.slick-slide img{
  width: 100%;
}
/* .slick-dots{
  bottom: 25px  !important;
  text-align: right !important;
}
.slick-dots li button{
    width: .9375rem;
    height: .9375rem;
    border: 0;
    border-radius: 50%;
    background: #0d543e;
    box-shadow: inset 0 2px 3px 0 #06281d;
    text-indent: 99999px;
    cursor: pointer;
}
.slick-dots li a:hover,.slick-dots li button:hover {
  background: #f2f2f2;
  cursor: pointer
}
.slick-active button,.slick-dots .flex-active,.slick-dots .slick-active button {
  
  background: #f2f2f2
} */

.slider-container {
  width: 80%;
  margin: 0 auto;
}

.slide {
  display: flex;
  justify-content: center;
  align-items: center;
  
  background: #333;
  color: #fff;
  font-size: 24px;
}

.slide img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}
.slide-header{
  height: 30px;
  background: #14805e;
}
 .slick-next{
  display: none;
 
}

.boder-right{
  border-right: 1px dashed #888;
}