@media screen and (min-width: 320px) and (max-width: 768px) {


}
.promotion {
    width: 100%;
    display: block;
}
.promotion_banner img {
    width: 100%;
}
.promotion-card img {
    width: 100%;
    /* border-bottom: dashed 2px transparent;  Removed since it may not be needed */
}
.promotion-card {
    background: #888;
    box-shadow: 0 2px 4px 0 rgba(15, 25, 46, .5);
    border-radius: 3px;
    position: relative; /* Added for absolute positioning of .line */
    margin: 10px 0px;
}
.promotion-body {
    text-align: start;
}
.promotion-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
}
.promotion-title h1 {
    font-size: 22px;
    line-height: 40px;
    width: 70%;
    color: #fff;
}
.promotion-title h1::before {
    content: "";
    width: .25rem;
    height: 1.875rem;
    background-image: linear-gradient(0deg, #ffdf18 3%, #ffe64b 100%);
    box-shadow: 0 2px 4px 0 rgba(15, 25, 46, .5);
    display: inline-block;
    margin-right: 0.5rem;
    vertical-align: middle;
}
.promotion-body {
    padding: 10px;
}
.promotion-title span {
    background: green;
    color: yellow;
    padding: 5px 1%;
    font-size: 14px;
}
.promotion-body p {
    padding: 0;
    margin: 0;
    font-size: 13px;
}
.promotion-card .line {
    position: relative;
    z-index: 1;
    top: -5px;
    left: 0;
    width: 100%;
    height: .4375rem;
    background: url(https://baji.live/images/v1/web/img-template/theme/green_black/promotion/line.png) repeat-x center;
}
