.casino-banner img {
  width: 100%;
}

input:focus {
  outline: none;
}

.casion-item {
  background: #333;
  position: relative;
  height: 100%;
  border-radius: 5px;
  
}

.casino-list {
  position: relative;
  text-align: center;
  align-items: center;
  
}

.casino-list img {
  border-radius: 2px;
  width: 100%;
  opacity: 0.7;
}

.casino .search-bar {
  width: 100%;
  height: 60px;
  background: #444;
  line-height: 60px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  position: relative;
}

.casino .search-bar input {
  flex: 1;
  max-width: 300px;
  background: #555;
  height: 40px;
  border: none;
  color: #fff;
  border-radius: 5px;
  padding: 0 20px 0 40px; /* Adjust padding to accommodate the icon */
}

input:focus {
  outline: none;
}

.casino-list-hover {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(15, 15, 15, 0.9);
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
  opacity: 0;
  width: 100%;
}

.casino-list:hover .casino-list-hover {
  display: flex;
  opacity: 1;
}

.casino-list-hover button {
  border: none;
  background: linear-gradient(to right, #fbc103 0, #f79604 15%, #f79604 30%, #fbc103 55%, #fbc103 100%);
  width: 80px;
  height: 30px;
  color: #fff;
  border-radius: 2px;
  margin: 10px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.casino-list-hover button:hover {
  background: linear-gradient(to right, #fbc103 0, #f79604 15%, #f79604 30%, #fbc103 55%, #fbc103 100%);
}

.casino-list-hover p {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin: 10px 0;
}

.search-bar .search-icon {
  position: absolute;
  left: 30px;
  color: #ccc;
}

.search-bar {
  position: relative;
}
.game-title{
  position: absolute;
  bottom: 0;
  background: linear-gradient(transparent, rgb(4, 216, 181, 0.5));
  color: #fff;
  width: 100%;
}