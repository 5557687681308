.footer{
    background: #363636;
    padding: 15px;
    width: 100%;
    z-index: 100;
}
.safe{
    display: block;
}
.safe img{
    filter: brightness(0) invert(0.5);
    margin: 0 10px;

}
.safe img:hover{
    filter:none;
}
.footer-logos-title{
    color: #fff;
    font-size: 13px;
    margin-bottom: 10px;
    
}
.payment-method{
    text-align: start;
}
.payment-method img{
    filter: brightness(0) invert(0.5);
    margin: 0 10px;
    width: 60px;
   
}
.payment-method img:hover{
    filter:none;
}