.nav-menu{
    background: #555;
    height: 40px;
    line-height: 40px;
    display: flex;
    color: #fff;
}
.nav-menu li{
    float: left;
    padding: 0px 10px;
    


}
.navmenu{
    width: 100%;
    display: flex;
    justify-content: space-between;
}